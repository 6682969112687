<template>
  <div class="card" v-show="news.show && news.show.length > 0">
      <div role="tablist" v-if="news.all">
        <b-card no-body class="mb-0 shadow-none"
            v-for="(item, index) in news.show"
            :key="item.id">
          <b-card-header header-tag="header" role="tab">
            <h6 class="m-0">
              <a
                v-b-toggle="'accordion-' + index"
                class="text-dark"
                href="javascript: void(0);"
              >{{item.title}}</a
              >
            </h6>
          </b-card-header>
          <b-collapse :id="'accordion-' + index" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <div style="color: #2C2C2C" v-html="item.content"></div>
              <div style="color: #979797;" class="float-end pt-1">{{item.datetime}}</div>
              <div class="clearfix"></div>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
  </div>
</template>

<script>
import NewsService from '@/services/News'

export default {
  data () {
    return {
      news: {}
    }
  },
  methods: {
    get () {
      NewsService.get()
        .then(response => {
          this.news = response.data
        })
        .catch(error => console.error(error.message))
    }
  },
  mounted () {
    this.get()
  }
}
</script>
