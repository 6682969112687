<template>
  <b-row>
    <b-col>
      <div class="row">
        <div class="col-sm-12 col-md-6">
          <div class="dataTables_length">
            <button type="button" class="btn btn-sm btn-primary me-1" @click="getMachineList()">
              <i class="fa fa-redo"></i>
            </button>
          </div>
        </div>
        <!-- Search -->
        <div class="col-sm-12 col-md-6">
          <div class="dataTables_filter text-md-end">
            <label class="d-inline-flex align-items-center fw-normal">
              <span class="uil-search"></span>
              <b-form-input
                v-model="filter"
                type="search"
                :placeholder="$t('gameservers.search')"
                class="form-control form-control-sm ms-2"
              ></b-form-input>
              <b-form-select
                v-model="perPage"
                size="sm"
                :options="pageOptions"
                class="form-select form-select-sm mx-2 w-50"
              ></b-form-select>
            </label>
          </div>
        </div>
        <!-- End search -->
      </div>
      <div class="table-responsive mb-0">
        <b-table
          table-class="table table-centered datatable table-card-list"
          thead-tr-class="bg-transparent"
          :fields="fields"
          :items="machineList"
          responsive="sm"
          :per-page="perPage"
          :current-page="currentPage"
          :filter="filter"
          @filtered="onFiltered"
        >
          <template v-slot:cell(alias)="items">
            <a href="javascript: void(0);" @click="$router.push(`userproducts/${items.item.id}`)" class="text-dark"> {{items.item.alias}}</a>
          </template>
          <template v-slot:cell(control)="items">
            <a
              href="javascript:void(0);"
              class="px-2 text-primary"
              :title="$t('links.server_management')"
              @click="$router.push(`userproducts/${items.item.id}`)"
            >
              <i class="uil uil-cog font-size-18"></i>
            </a>
          </template>
        </b-table>
      </div>
      <div class="row">
        <div class="col">
          <div class="dataTables_paginate paging_simple_numbers float-end">
            <ul class="pagination pagination-rounded">
              <!-- pagination -->
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
              ></b-pagination>
            </ul>
          </div>
        </div>
      </div>
    </b-col>
  </b-row>
</template>
<script>
import axios from '@/modules/Axios'
import News from '@/views/pages/client/news'

export default {
  data () {
    return {
      machineList: [],
      filter: '',
      totalRows: 1,
      currentPage: 1,
      pageOptions: [10, 25, 50, 100],
    }
  },
  computed: {
    fields () {
      return [
        {
          key: 'alias',
          label: this.$t('machines.name'),
          sortable: true,
        },
        {
          key: 'control',
          label: this.$t('gameservers.control'),
          tdClass: 'd-inline-flex align-items-center',
          thStyle: {
            width: '100px',
          },
        }
      ]
    },
    perPage: {
      get: function () {
        return this.$store.state.layout.perPage
      },
      set: function (newValue) {
        this.$store.commit('layout/CHANGE_PER_PAGE', newValue)
      }
    }
  },
  notifications: {notify: {}},
  methods: {
    async getMachineList () {
      // Отправляем запрос на сервер
      try {
        // Отправляем запрос на сервер
        const response = await axios.post('Userproducts.php')

        // Если ответ пуст - завершаем выполнение метода
        if (typeof response.data === 'undefined' || !response.data) return

        this.machineList = []
        response.data.forEach(machine => this.machineList.push(machine))
        this.totalRows = this.machineList.length;
      } catch (error) {
        console.error(error.message)
      }
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
  mounted () {
    this.getMachineList()
  }
}
</script>

